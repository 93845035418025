import React from 'react';

import SEO from '../components/seo';
import Layout from '../components/layout';
import CoursePneuma from '../components/course/coursePneuma';

const Pneuma = () => {
  return (
    <>
      <SEO title="Pneuma" />
      <Layout>
        <CoursePneuma />
      </Layout>
    </>
  );
};

export default Pneuma;
