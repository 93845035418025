import React from 'react';
import { Row, Col } from 'antd';

import Styles from './course-ind.module.css';
import HeroPneuma from '../image-components/hero/heroPneuma';
import TabAbout from '../image-components/tab/tabAbout';
import TabOverview from '../image-components/tab/tabOverview';
import TabGuide from '../image-components/tab/tabGuide';

import GuideImg from '../../images/guide-doulos.jpeg';

const CoursePneuma = () => {
  return (
    <>
      <HeroPneuma />
      <Row className={Styles.row}>
        <Col className={Styles.col_left} sm={24} md={16}>
          <div className={Styles.heading}>PNEUMA ADVANCE</div>
          <div className={Styles.body}>
            <div className={Styles.subtitle}>
              <div>Being Transformed via the Spirit of God.</div>
              <p>
                Ekko's Pneuma Advance is centered on the learning and practice
                of the supernatural gifts of the Holy Spirit—the recognition and
                yearning for glimpses of heaven to be actualized on earth.
              </p>
              <p>
                When we become part of a tribe in the Kingdom of Heaven, we are
                given the opportunity to practice and release the power of God
                for the edification, encouragement, and healing of our world. It
                is in the context of covenant community that we train, learn,
                and grow.
              </p>
            </div>
          </div>
          <div className={Styles.body}>
            <div className={Styles.quote}>
              "Church is the core element in the strategy of the Holy Spirit for
              providing human witness and physical presence to the
              Jesus-inaugurated kingdom of God in this world. It is not that
              kingdom complete, but it is a witness to that kingdom."
            </div>
            <div className={Styles.author}>EUGENE H. PETERSON</div>
          </div>
        </Col>
        <Col className={Styles.col_right} sm={24} md={8}>
          <TabAbout>
            <div className={Styles.modal_title}>ABOUT</div>
            <div className={Styles.modal_body}>
              <h2 className={Styles.modal_subtitle}>The Learning Outcomes</h2>
              <p className={Styles.subheading}>Advance participants will:</p>
              <ol>
                <li>
                  Participants will understand Ekko Church's position on
                  pneumatology (the study of the Holy Spirit), the supernatural
                  spiritual gifts, and the biblical instructions and
                  prohibitions on their practice within the context of the local
                  church.
                </li>
                <li>
                  Participants will be able to understand each of the spiritual
                  gifts.
                </li>
                <li>
                  Participants should be able to responsibly practice the
                  spiritual gifts within the context of the local church, as
                  well as missionally to the world.
                </li>
              </ol>
              <h2 className={Styles.modal_subtitle}>The Big Idea</h2>
              <p className={Styles.subheading}>Cohort Based</p>
              <p>
                Ekko's Pneuma Advance organizes participants to journey together
                through the entire program. In the duration of six weeks,
                participants’ primary learning and practice come from their
                willingness to be humble and teachable throughout what can be a
                mysterious process. In so doing, they learn largely from each
                other, gaining stories with which to tell others about God's
                faithfulness.
              </p>
              <p className={Styles.subheading}>Advisor-Directed</p>
              <p>
                Pneuma Advance is paired with a guide (Isaac Chong) and
                assistants who will facilitate the weekly meetings.
              </p>
              <p className={Styles.subheading}>Online & Hybrid</p>
              <p>
                Pneuma Advance engages in a variety of venues that are
                EPIC—experiential, participatory, image-driven and connective.
                It does not encourage simply downloading information; instead,
                participants and advisors value relationship, the dynamic
                interaction, and experimentation, which will enrich and deepen
                its outcome. The format comprise of weekly discussions online
                and weekly face-to-face meetings with group members.
              </p>
            </div>
          </TabAbout>
          <TabOverview>
            <div className={Styles.modal_title}>OVERVIEW</div>
            <div className={Styles.modal_body}>
              <div className={Styles.modal_paragraph}>
                {/* <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Logos/Logos%20Syllabus%20S2021.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className={Styles.btn_syllabus}>Syllabus</button>
                </a> */}
                <div className={Styles.modal_subtitle}>Week 1</div>
                <div className={Styles.subheading}>
                  The Supernatural & The Gift of Discernment
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 2</div>
                <div className={Styles.subheading}>
                  The Gifts of Prophecy & Knowledge
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 3</div>
                <div className={Styles.subheading}>The Gifts of Healings</div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 4</div>
                <div className={Styles.subheading}>
                  Dreams, Visions, & The Gift of Wisdom
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 5</div>
                <div className={Styles.subheading}>
                  The Gifts of Miracles & Faith
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 6</div>
                <div className={Styles.subheading}>
                  Intercession, The Gift of Tongues, & The Church
                </div>
              </div>
            </div>
          </TabOverview>
          <TabGuide>
            <div className={Styles.modal_title}>GUIDE</div>
            <div className={Styles.modal_body}>
              <img
                className={Styles.guide_img}
                src={GuideImg}
                alt="Pastor Isaac"
              />
              <div className={Styles.guide_name}>Isaac Chong</div>
              <div className={Styles.guide_subheading}>
                <div>Pastor of Ekko Church & Operations Director</div>
                <div>Pneuma Advance Guide</div>
              </div>
              <p>
                Since the age of 21, when he had a supernatural encounter with
                the Holy Spirit, Isaac has been passionate about the gifts of
                the Spirit, the manifest presence of God, and the participation
                of the local church in the renewal of all things. He has a
                Bachelor in Economics, concentrating on third-world development,
                from UC Santa Cruz, a Master of Divinity from Fuller Theological
                Seminary, and a certificate in Church Administration from North
                Park University in Chicago. He is married to the love of his
                life, Grace, and they have two children: Hope and Zachary.
              </p>
            </div>
          </TabGuide>
        </Col>
      </Row>
    </>
  );
};

export default CoursePneuma;
